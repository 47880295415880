'use client'

import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { useEffect } from "react";

 
export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
    // first attempt : redirect to current page but add clear-cache query param
    // second attempt : show error message
    const param = useSearchParams();
    const pathName = usePathname();
    const router = useRouter();

    useEffect(() => {
        if (error.digest && !param.get("clear-cache")) {
            router.push(`${pathName}?clear-cache=true`);
        } else {
            console.error(error);
        }
    }, [error, param, pathName, router])

    return (
        <html>
            <body>
                <h2>มีข้อผิดพลาดเกิดขึ้น</h2>
            </body>
        </html>
    )
}